import $ from 'jquery';
window.$ = window.jQuery = $;

import './hamburger';
import './gallery';

require('./jquery.nivo.slider.pack');

$(window).on('load', function() {
    $('#slides').nivoSlider({
        effect: 'random',
        slices: 15,
        animSpeed: 500,
        pauseTime: 4000,
        startSlide:0,
        directionNav: false,
        directionNavHide: false,
        controlNav: false,
        controlNavThumbs: false,
        controlNavThumbsFromRel: false,
        keyboardNav: false,
        pauseOnHover: true,
        manualAdvance: false
    });
});

document
    .querySelector(".hamburger")
    .addEventListener("click", () => {
        document.querySelector(".hamburger").classList.toggle("hamburger--open");
        document
            .querySelector(".nav")
            .classList.toggle("nav--active");
        document
            .querySelector(".lang-select")
            .classList.toggle("lang-select--active");
    });
